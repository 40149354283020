import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HourlySales, OrdersByChannelWithCumulativeValue } from '@dki/api-client';
import { getLocale } from '@libs/dash/core';

import { Range, sortChannels } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { TranslateService } from '@libs/shared/modules/i18n';
import { ExportToCsv } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import { cloneDeep, toLower } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { REPORTS_FACADE } from '../../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../../facade/reports-facade.provider.interface';

export interface HourlyInternalDataTable {
	columns: string[];
	rowGroups: {
		[hour: number]: {
			[id: string]: {
				isParent: boolean;
				hourFrom: string;
				hourTo: string;
				values: any[];
			};
		};
	};
	totals: any[];
}

@Component({
	selector: 'dk-hourly-sales-tile',
	templateUrl: './hourly-sales-tile.component.html',
	styleUrls: ['./hourly-sales-tile.component.scss'],
})
export class HourlySalesTileComponent implements OnInit {
	isLoading = this.reportsServiceProvider.isLoadingHourlySales$;

	dateRange = new FormGroup({
		from: new FormControl(this.reportsServiceProvider.ranges.hourlyReport.from.toJSDate()),
		to: new FormControl(this.reportsServiceProvider.ranges.hourlyReport.to.toJSDate()),
	});

	expanded: boolean[];
	filters = [];

	dataTable: HourlyInternalDataTable;

	salesChannels = [];

	i18n: Record<string, any>;
	filterUpdates = new BehaviorSubject<boolean>(false);

	restaurants = [];

	public viewData$ = combineLatest([
		this.filterUpdates,
		this.reportsServiceProvider.hourlySales$.pipe(
			filter((x) => !!x)
			// map((x: HourlySales) => this.prepareTableDataSource(x, this.filters)),
		),
		this.translateService.selectTranslation('hourlySalesTile'),
		this.translateService.selectTranslation('channels'),
	]).pipe(
		map(([, dataTable, i18n, channels]) => {
			this.dataTable = this.prepareTableDataSource(dataTable, this.filters);
			this.i18n = i18n;
			this.i18n.channels = channels;
			return { dataTable: this.dataTable, i18n };
		})
	);

	constructor(
		@Inject(REPORTS_FACADE) private readonly reportsServiceProvider: ReportsServiceProvider,
		private readonly translateService: TranslateService,
		private readonly fmService: FeatureMapService
	) {}

	ngOnInit(): void {
		this.reportsServiceProvider.selectedRestaurants$.subscribe((restaurants) => {
			this.restaurants = restaurants.map((rest) => rest?.name);
		});
	}

	prepareTableDataSource(rawData: HourlySales, filter = []) {
		const minutesOffset = 15;
		const _rawData = cloneDeep(rawData);
		// hiding column with feature map
		const fmFilter = this.fmService?.featureMap?.salesReports?.hourlyHideColumns.map((e) => e.toLowerCase()) || [];

		const filterChannels = (data: HourlySales, filters: Array<string>, exclude: boolean) => {
			const _filters = filters.map(toLower);
			if (_filters.length > 0) {
				Object.values(data.by_time).forEach((element) => {
					for (const key in element.by_channel) {
						if (exclude ? !_filters.includes(key.toLowerCase()) : _filters.includes(key.toLocaleLowerCase())) {
							delete element.by_channel[key];
						}
					}
				});
				for (const key in data.total.by_channel) {
					if (exclude ? !_filters.includes(key.toLocaleLowerCase()) : _filters.includes(key.toLowerCase())) {
						delete data.total.by_channel[key];
					}
				}
			}
		};
		filterChannels(_rawData, fmFilter, false);
		this.salesChannels = Object.keys(_rawData.total.by_channel).sort(sortChannels);
		filterChannels(_rawData, filter, true);

		const timeOffset = parseInt(Object.keys(_rawData.by_time)[0]?.split('+')[1]) || 2;
		const rowGroups: { [hour: number]: { [id: string]: { isParent: boolean; hourFrom: string; hourTo: string; values: any[] } } } = {};
		let columns = Object.keys(_rawData.total.by_channel).sort(sortChannels);
		let totals = [];

		columns = Object.keys(_rawData.total.by_channel).sort(sortChannels);

		for (const [key, value] of Object.entries(_rawData.by_time)) {
			const date = DateTime.fromISO(key).setZone(`UTC+${timeOffset}`);
			const hour = date.startOf('hour').hour;
			const time = date.toFormat('HH-mm');
			const rowGroup = rowGroups[hour] ?? {};
			let values = [];

			const tmp = this.sortObject((value as OrdersByChannelWithCumulativeValue).by_channel);
			values = Object.values(tmp).map((x) => x);
			values.unshift({ count: 0, value: 0, mean_value: 0, cumulative_value: 0 }); // total
			const parentRow = rowGroup['00'] ?? {
				isParent: true,
				hourFrom: hour.toString(),
				hourTo: (hour + 1).toString(),
				values: new Array(values.length).fill(0).map(() => ({ count: 0, value: 0, mean_value: 0, cumulative_value: 0 })),
			};
			const total = _rawData.by_time[key].total;
			values[0] = total;
			rowGroup['00'] = parentRow;
			rowGroup[time] = {
				isParent: false,
				hourFrom: `${hour}:${date.minute || '00'}`,
				hourTo: date.minute + minutesOffset === 60 ? `${hour + 1}:00` : `${hour}:${date.minute + minutesOffset || '00'}`,
				values,
			};
			rowGroups[hour] = rowGroup;
			values.forEach((x, i) => {
				rowGroup['00'].values[i].count += x.count;
				rowGroup['00'].values[i].value += x.value;
				if (x.mean_value) {
					if (rowGroup['00'].values[i].mean_value === 0) {
						rowGroup['00'].values[i].mean_value = x.mean_value;
					} else {
						rowGroup['00'].values[i].mean_value = rowGroup['00'].values[i].value / rowGroup['00'].values[i].count;
					}
				}
				rowGroup['00'].values[i].cumulative_value = Math.max(x.cumulative_value, rowGroup['00'].values[i].cumulative_value);
			});
		}

		this.expanded = new Array(Object.keys(rowGroups).length).fill(false);

		columns.unshift('TOTAL');
		totals = new Array(columns.length).fill(0).map(() => ({ count: 0, value: 0, mean_value: 0, cumulative_value: 0 }));
		Object.values(rowGroups).forEach((e) => {
			const current = e['00'];
			current.values.forEach((x, i) => {
				totals[i].count += x.count;
				totals[i].value += x.value;
				totals[i].cumulative_value = Math.max(x.cumulative_value, totals[i].cumulative_value);
			});
		});
		totals.forEach((e, i) => (e.mean_value = e.count ? e.value / e.count : 0));
		return { columns, rowGroups, totals: totals };
	}

	setPeriod(period: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
			case Range.Next:
				from = from.plus({ days: 7 });
				to = to.plus({ days: 7 });
				break;
			case Range.Prev:
				from = from.plus({ days: -7 });
				to = to.plus({ days: -7 });
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.reportsServiceProvider.getHourlySales(from, to);
	}

	hourRowClick(rowIndex: number) {
		this.expanded[rowIndex] = !this.expanded[rowIndex];
	}

	originalOrder = () => 0;

	handleFilterChange(filters) {
		this.filters = filters;
		this.filterUpdates.next(true);
	}

	salesChannelheader(label, key) {
		if (label && label.toLowerCase().includes('total')) return label;
		return label === undefined ? key : label.split(' ')[1] == 'EMP' ? '' : label.split(' ')[0];
	}

	downloadCsv() {
		const csv = [];
		const channels = this.dataTable.columns;
		Object.values(this.dataTable.rowGroups).forEach((el, vi) => {
			Object.values(el).forEach((valuesPerTimeOffset) => {
				const entry = {};
				channels.forEach((channel, chi) => {
					const time = `${valuesPerTimeOffset.hourFrom} - ${valuesPerTimeOffset.hourTo}h`;
					const count = valuesPerTimeOffset.values[chi].count;
					const value = valuesPerTimeOffset.values[chi].value.toFixed(2);
					const mean_value = (valuesPerTimeOffset.values[chi].mean_value || 0).toFixed(2);
					const translatedChannel = this.i18n.channels[channel] || channel;
					entry['time'] = time;
					if (this.singleDaySelection()) {
						const cumulative_value = valuesPerTimeOffset.values[chi].cumulative_value.toFixed(2);
						entry[`${translatedChannel}-CUMUL`] = cumulative_value;
					}
					entry[`${translatedChannel}-CA`] = value;
					entry[`${translatedChannel}-NB`] = count;
					entry[`${translatedChannel}-MOY`] = mean_value;
				});
				csv.push(entry);
			});
		});
		const options = {
			filename: this.i18n.title,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.title,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		// reshuffle the csv to match the report apparence starting from 6 am
		const rotatedExportedCSV = csv.slice(5 * 6).concat(csv.slice(0, 5 * 6));
		csvExporter.generateCsv(rotatedExportedCSV);
	}

	downloadXlsx(): void {
		const hourlySalesOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headerRow = ['Heure'];
				this.dataTable.columns.forEach((channel) => {
					if (this.singleDaySelection()) {
						headerRow.push(`${this.i18n.channels[channel] || channel} CUMUL`);
					}
					headerRow.push(
						`${this.i18n.channels[channel] || channel} CA`,
						`${this.i18n.channels[channel] || channel} NB`,
						`${this.i18n.channels[channel] || channel} MOY`
					);
				});

				const headerRowRef = sheet.addRow(headerRow);
				headerRowRef.eachCell((cell) => {
					cell.font = { bold: true };
				});
				return headerRowRef;
			},

			prepareDataRows: (sheet) => {
				Object.values(this.dataTable.rowGroups).forEach((group) => {
					Object.values(group).forEach((row) => {
						let dataRow = [`${row.hourFrom}-${row.hourTo}`];
						row.values.forEach((value) => {
							if (this.singleDaySelection()) {
								dataRow.push(value.cumulative_value.toFixed(2));
							}
							dataRow.push(value.value.toFixed(2), value.count.toString(), value.mean_value.toFixed(2));
						});
						sheet.addRow(dataRow);
					});
				});
			},

			generateFileName: () => {
				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
				const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
				return `VentesHoraires-${from}-${to}.xlsx`;
			},
		};

		const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
		const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
		const detailText = `Restaurant : ${this.restaurants.join(', ')}\nDate: Du ${from} au ${to}`;

		generateXlsx('Ventes Horaires', detailText, hourlySalesOptions);
	}

	sortObject(obj) {
		return Object.keys(obj)
			.sort(sortChannels)
			.reduce((res, key) => ((res[key] = obj[key]), res), {});
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	downloadPDF() {
		const source = document.getElementById('hourly-report');
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.html(source, {
			html2canvas: {
				scale: 0.1,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('op'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.setFontSize(16);
				doc.setFont(undefined, 'bold');
				doc.text(this.restaurants.join(' '), 20, 15);
				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
					to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
				const date = from === to ? from : `${from} - ${to}`;
				doc.setFontSize(12);
				doc.setFont(undefined, 'light');
				doc.text(`${date}`, 20, 20);
				doc.save(
					`${this.i18n.title}-${DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd')}_${DateTime.fromJSDate(
						this.dateRange.controls.to.value
					).toFormat('yyyy-MM-dd')}`
				);
			},
		});
	}
}
