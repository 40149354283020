import { MenuSize, ProductPriceType } from '@bk/price-management-common';

export const MAX_LENGTH_INPUT = '256';
export const REFRESH_TOKEN_INTERVAL = 1000;

export const HTTP_ERRORS = {
	UNAUTHORIZED: 401,
};

export const MAX_IMPORT_FILE_SIZE: number = 2 * 1024 * 1024;
export const ALLOWED_FILE_TYPES: string[] = ['csv'];

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const SMALL_DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 20;

export const NO_TOAST_URLS = ['auth/login', 'auth/logout', 'my/profile', 'v1/my/password', 'v1/users', 'data/update', 'appupdate' ];

export const SKIPPED_BREADCRUMB = ['fullscale-ui', 'v2/fullscale-ui', 'v2'];
export const HIDDEN_BREADCRUMB = [];

export const ROUTE_PATHS = {
	unauthorized: 'unauthorized',
	pageNotFound: 'page-not-found',
	pricesImport: 'prices-import',
	pricesExport: 'prices-export',
	priceManagement: 'price-management',
	allergenManagement: 'allergen-management',
	priceList: 'price-list',
	userManagement: 'user-management',
	restaurantManagement: 'restaurant-management',
	fullscaleUi: 'fullscale-ui',
	v2: 'v2',
	myProfile: 'profile',
	about: 'about',
	login: 'login',
	logout: 'logout',
	dashboard: 'dashboard',
	monitoring: 'monitoring',
	restaurantsList: 'restaurants-list',
	ownerManagement: 'owner-management',
	paymentManagement: 'payment-management',
	tagManagement: 'tag-management',
	optionsManagement: 'options-management',
	iframedParts: 'iframe-parts',
	mediaManagement: 'media-management',
	mediaManagementMediaEditor: 'media-editor',
};

export const SECTION_TITLES = {
	CHANGE_PW: 'section-changepw',
	GENERAL: 'section-general',
	PERMISSIONS_MANAGEMENT: 'section-rights',
	USER_INFO: 'section-userdetails',
	USER_INFO_ALL_PERMISSIONS: 'SECTION_USER_INFO_ALL_PERMISSIONS',
	USER_PASSWORD: 'section-userpassword',
	RESTAURANTS: 'section-restaurants',
	RESTAURANT_INFO: 'section-restaurantsinfo',
	LEGACY_APP_PERMISSIONS_MANAGEMENT: 'section-legacy-app-permissions',
	API_PERMISSIONS: 'section-legacy-app-permissions',
	WEB_ORDER_PERMISSIONS: 'SECTION_WEB_ORDER_PERMISSIONS',
	RESTAURANT_IDENTIFICATION: 'section-restaurant-identification',
	RESTAURANT_CONSUMPTION_TARIFFS: 'section-restaurant-tariffs',
	LOCAL_PRICE_MANAGEMENT: 'section-local-price-management',
	PARTNERSHIP_CONSUMPTION_TARIFFS: 'section-partnership-tariffs',
	TVA_TARIFF: 'section-tva-tariff',
	SALES_CHANNELS: 'section-sales-channel',
	RESTAURANT_TAGS: 'section-resto-tags',
	RESTAURANT_SETTINGS: 'section-resto-settings',
	RESTAURANT_DTT: 'section-resto-dtt',
	RESTAURANT_FUNCITONALITIES: 'section-resto-functionalites',
	RESTAURANT_ADDRESS: 'section-restaurant-address',
	RESTAURANT_CONTACT: 'section-restaurant-contact',
	RESTAURANT_MERCHANT_OPTIONS: 'section-restaurant-merchant-options',
	RESTAURANT_OPTIONS_VALUES: 'section-restaurant-features',
	RESTAURANT_OPENING_TIMES: 'section-restaurant-opening-times',
	RESTAURANT_STATE: 'section-restaurant-state',
	RESTAURANT_PAYMENT_FEATURES: 'section-restaurant-payment-features',
	RESTAURANT_OTHER_FEATURES: 'section-restaurant-other-features',
	RESTAURANT_ZELTY_OPTIONS: 'section-restaurant-zelty-options',
	RESTAURANT_FISCAL_OPTIONS: 'SECTION_RESTAURANT_FISCAL_OPTIONS',
	RESTAURANT_LEGAL_OPTIONS: 'SECTION_RESTAURANT_LEGAL_OPTIONS',
	RESTAURANT_WEB_ORDER_CHANNELS: 'SECTION_RESTAURANT_WEB_ORDER_CHANNELS',
	OWNER_IDENTIFICATION: 'SECTION_OWNER_IDENTIFICATION',
	OWNER_ADDRESS: 'SECTION_OWNER_ADDRESS',
	OWNER_BUSINESS_DETAILS: 'SECTION_OWNER_BUSINESS_DETAILS',
	PAYMENT_METHOD_IDENTIFICATION: 'SECTION_PAYMENT_METHOD_IDENTIFICATION',
	PAYMENT_METHOD_OPTIONS: 'SECTION_PAYMENT_METHOD_OPTIONS',
	PAYMENT_METHOD_CHANGE_OPTIONS: 'SECTION_PAYMENT_METHOD_CHANGE_OPTIONS',
	PAYMENT_METHOD_METADATA: 'SECTION_PAYMENT_METHOD_METADATA',
	TAG_INFO: 'SECTION_TAG_INFO',
	ALLERGEN_INFO: 'SECTION_ALLERGEN_INFO',
};

export const TAB_LABELS = {
	GENERAL: 'tab-general',
	CONTACTS: 'tab-contacts',
	PARTNERSHIPS: 'tab-partnerships',
	PRICE_LEVELS: 'tab-price-levels',
	FEATURES_TAGS: 'tab-features-tags',
	OPENING_TIMES: 'tab-opening-times',
	FISCAL_OPTIONS: 'TAB_FISCAL_OPTIONS',
};

export const BUTTON_LABELS = {
	NEXT: 'next-button',
	BACK: 'back-button',
};

export const SECTION_DESCRIPTIONS = {
	CHANGE_PW: 'section-description-changepw',
	PERMISSIONS_MANAGEMENT: 'section-description-rights',
	USER_INFO: 'section-description-userinfo',
	USER_INFO_ALL_PERMISSIONS: 'SECTION_DESCRIPTION_USERINFO_ALL_PERMISSIONS',
	USER_PASSWORD: 'section-description-userpassword',
	RESTAURANTS: 'section-description-restaurants',
	RESTAURANT_INFO: 'section-description-restaurantsinfo',
	LEGACY_APP_PERMISSIONS_MANAGEMENT: 'section-description-legacy-app-permissions',
	API_PERMISSIONS: 'section-legacy-app-permissions',
	WEB_ORDER_PERMISSIONS: 'SECTION_DESCRIPTION_WEB_ORDER_PERMISSIONS',
	RESTAURANT_TARIFFS: 'section-description-restaurant-tariffs',
	IN_RESTAURANT_TARIFFS: 'section-description-in-restaurant-tariff',
	LOCAL_PRICE_MANAGEMENT_SUB: 'section-local-price-management-sub',
	LOCAL_PRICE_MANAGEMENT_AUTHORIZED_LOCAL: 'section-local-price-management-authorized-in-restaurant',
	LOCAL_PRICE_MANAGEMENT_AUTHORIZED_DELIVERY: 'section-local-price-management-authorized-delivery',
	PARTNERSHIP_CONSUMPTION_TARIFFS: 'section-description-partnership-consumption-tariffs',
	TVA_TARIFF: 'section-description-tva-tariff',
	SALES_CHANNELS: 'section-description-sales-channel',
	RESTAURANT_TAGS: 'section-description-resto-tags',
	RESTAURANT_SETTINGS: 'section-description-resto-settings',
	RESTAURANT_DTT: 'section-description-resto-dtt',
	RESTAURANT_FUNCITONALITIES: 'section-description-resto-functionalites',
	RESTAURANT_ADDRESS: 'section-restaurant-address-description',
	RESTAURANT_CONTACT: 'section-restaurant-contact-description',
	RESTAURANT_MERCHANT_OPTIONS: 'section-restaurant-merchant-options-description',
	RESTAURANT_PAYMENT_FEATURES: 'section-restaurant-payment-features-description',
	RESTAURANT_OTHER_FEATURES: 'section-restaurant-other-features-description',
	RESTAURANT_OPENING_TIMES: 'section-restaurant-opening-times-description',
	RESTAURANT_STATE: 'section-restaurant-state-description',
	RESTAURANT_ZELTY_OPTIONS: 'section-restaurant-zelty-options-description',
	RESTAURANT_FISCAL_OPTIONS: 'SECTION_RESTAURANT_FISCAL_OPTIONS_DESCRIPTION',
	RESTAURANT_LEGAL_OPTIONS: 'SECTION_RESTAURANT_LEGAL_OPTIONS_DESCRIPTION',
	RESTAURANT_WEB_ORDER_CHANNELS: 'SECTION_RESTAURANT_WEB_ORDER_CHANNELS_DESCRIPTION',
	OWNER_IDENTIFICATION: 'SECTION_OWNER_IDENTIFICATION_DESCRIPTION',
	OWNER_ADDRESS: 'SECTION_OWNER_ADDRESS_DESCRIPTION',
	OWNER_BUSINESS_DETAILS: 'SECTION_OWNER_BUSINESS_DETAILS_DESCRIPTION',
	PAYMENT_METHOD_IDENTIFICATION: 'SECTION_PAYMENT_METHOD_IDENTIFICATION_DESCRIPTION',
	PAYMENT_METHOD_OPTIONS: 'SECTION_PAYMENT_METHOD_OPTIONS_DESCRIPTION',
	PAYMENT_METHOD_CHANGE_OPTIONS: 'SECTION_PAYMENT_METHOD_CHANGE_OPTIONS_DESCRIPTION',
	PAYMENT_METHOD_METADATA: 'SECTION_PAYMENT_METHOD_METADATA_DESCRIPTION',
	TAG_INFO: 'SECTION_TAG_INFO_DESCRIPTION',
	ALLERGEN_INFO: 'SECTION_DESCRIPTION_ALLERGEN_INFO',
};

export const RESTAURANT_CHIP_TITLES = {
	TAG: {
		TITLE: 'tag-title',
		PLACEHOLDER: 'tag-placeholder',
	},
	DTT: {
		TITLE: 'dtt-title',
		PLACEHOLDER: 'dtt-placeholder',
	},
};

export const USER_CREATE_FIELDS = {
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	EMAIL: 'email',
	PHONE: 'phone',
	LANDLINE: 'landline',
	ALL_RESTAURANTS: 'has_all_restaurants',
	PERMISSIONS: 'permissions',
	IS_ADMIN: 'is_admin',
	WEB_ORDER_CHANNELS: 'weborder_channels',
};

export const MAX_VALUE_INTEGER_IDENTIFICATIONS = 2147483647;

export const MAX_CHAR_LENGTH_IDENTIFICATIONS = 32;

export const RESTAURANT_CREATE_FIELDS = {
	NAME: 'name',
	NUMBER_FR: 'frnb',
	NUMBER_BK: 'bknb',
	UUID: 'uuid',
	XTAG: 'xtag',
	TAGS: 'tags',
	SALES_CHANNELS: 'salesChannels',
	WEB_ORDER_CHANNELS: 'webOrderChannels',
	VAT_GROUP: 'vatGroup',
	DTT: {
		SECOND_GROUP: 'secondDTTGroup',
		MAIN_GROUP: 'mainDTTGroup',
	},
	RESTO_STATE_GROUP: 'restoStateGroup',
	RESTO_STATE: 'restoState',
	STATE: {
		UNKNOWN: 'UNKNOWN',
		CONFIG: 'CONFIG',
		INSTALL: 'INSTALL',
		WHITE: 'WHITE',
		PRODUCTION: 'PRODUCTION',
	} as const,
	PUBLICATION: 'publication',
	PUBLISH: 'publish',
	UNPUBLISH: 'unpublish',
	PRICE_SET: 'priceSet',
	NB: {
		DMB: 'nbDMB',
		DRIVE: 'nbDrive',
		KDS: 'nbKDS',
		ORBP: 'nbORBp',
		ORBs: 'nbORBs',
		POS: 'nbPOS',
	},
	IDENTIFICATION: {
		BK: 'bknb',
		FR: 'frnb',
		UUID: 'uuid',
		ID: 'id',
	},
	ENABLE: {
		DEFFERED_PAY: 'enableDeferredPay',
		KINGDOM: 'enableKingdom',
		MULTIPAY_ON_KIOSK: 'enableMultipayOnKiosk',
		ORB_DIGITAL: 'enableORBDigital',
		PAYMENT_CARTE_GR: 'enablePaymentCarteGR',
		SOURCES: 'enableSources',
		WAIT_AT_THE_TABLE: 'enableWaitAtTheTable',
		TABLE_SERVICE: 'enableTableService',
		COUPON_PAYMENT: 'enableICouponPay',
	},
	LOCAL_PRICE_MANAGEMENT: 'localPriceManagement',
	LOCAL_PRICE_MANAGEMENT_DELIVERY: 'localPriceManagementDelivery',
	ASSEMBLY_AREAS: 'assemblyAreas',
	FEATURES: 'features',
	PRICE_LEVELS: {
		EAT_IN: 'eatIn',
		TAKE_AWAY: 'takeAway',
		DELIVEROO: 'deliveroo',
		UBER_EATS: 'uberEats',
		JUST_EAT: 'justEat',
		DELIVERY_BK: 'deliveryBK',
	},
	ADDRESS_OBJECT: 'address',
	ADDRESS: {
		STREET: 'adr_adresse',
		CITY: 'adr_city',
		COMPLEMENT: 'adr_complement',
		COUNTRY: 'adr_country',
		EXTRA_ADDRESS: 'adr_extra',
		PC: 'adr_postal_code',
		LONGITUDE: 'longitude',
		LATITUDE: 'latitude',
	},
	CONTACT_OBJECT: 'contact_obj',
	CONTACT: {
		EMAIL: 'email',
		MOBILE: 'mobile',
		NAME: 'name',
		TELEPHONE: 'telephone',
	},
	OPENING_TIMES_OBJECT: 'opening_times',
	OPENING_TIMES: {
		FROM: 'from',
		TO: 'to',
	},
	OPENING_DAYS: {
		MONDAY: 'monday',
		TUESDAY: 'tuesday',
		WEDNESDAY: 'wednesday',
		THURSDAY: 'thursday',
		FRIDAY: 'friday',
		SATURDAY: 'saturday',
		SUNDAY: 'sunday',
	} as const,
	INSTALLATOR: 'installator',
	GROUPS: 'restaurantsGroups',
	TPE_ID_MERCHANT: 'tpe_id_merchant',
	URL_NEPTING: 'url_nepting',
	DTT_GROUP_PRIMARY: 'dtt_group_primary',
	DTT_GROUP_SECONDARY: 'dtt_group_secondary',
	FISCAL_INFO: 'fiscal_info',
	PLATFORM_ID: 'platform_id',
	ZELTY_OPTIONS_OBJECT: 'zeltyOptions',
	ZELTY_OPTIONS: {
		KIOSK_API_KEY: 'kioskApiKey',
		SYNCHRONIZATION_KEY: 'synchronizationKey',
	},
	FISCAL_OPTIONS_OBJECT: 'fiscalOptions',
	FISCAL_OPTIONS: {
		PAYMENT_METHODS_OBJECT: 'paymentMethods',
		PAYMENT_METHOD: {
			PAYMENT_METHODS: 'paymentMethods',
			IS_ENABLED: 'isEnabled',
			MANAGER_CODE_REQUIRED: 'managerCodeRequired',
		},
		CLOSURE_PAYMENT_METHOD: 'closurePaymentMethod',
		OWNER: 'owner',
		NAME_EXTRA: 'nameExtra',
		ESTABLISHMENT_ID: 'establishmentId',
	} as const,
};

export const OWNER_CREATE_FIELDS = {
	ENTITY_ID: 'entityId',
	NAME: 'name',
	VAT_NUMBER: 'vatNumber',
	BUSINESS_CODE: 'businessCode',
	ADDRESS_OBJECT: 'addressObject',
	ADDRESS: {
		ADDRESS_PRIMARY: 'address',
		ADDRESS_EXTRA: 'addressExtra',
		ZIP_CODE: 'zipCode',
		CITY: 'city',
		COUNTRY: 'country',
	},
	BUSINESS_DAY_START_TIME: 'businessDayStartTime',
	FISCAL_YEAR_START_DATE_OBJECT: 'fiscalYearStartDate',
	FISCAL_YEAR_START_DATE: {
		START_DAY: 'startDay',
		START_MONTH: 'startMonth',
	},
	ID: 'id',
};

export const PAYMENT_METHOD_CREATE_FIELDS = {
	LABEL: 'label',
	ALLOW_OVERPAYMENT: 'allowOverpayment',
	CHANGE_OBJECT: 'changeObject',
	CHANGE: {
		ALLOW_CHANGE: 'allowChange',
		CHANGE_ID: 'changeId',
	},
	MAX_AMOUNT: 'maxAmount',
	ALLOW_MANAGER_CODE: 'allowManagerCode',
	METADATA: 'metadata',
	ID: 'id',
};

export const TAG_CREATE_FIELDS = {
	NAME: 'name',
	ID: 'id',
	DESCRIPTION: 'description',
};

export const FORM_ERRORS = {
	REQUIRED: 'required',
	NOT_EMAIL: 'email',
	NOT_SAME: 'notSame',
	NOT_UNIQUE: 'notUnique',
	PATTERN: 'pattern',
	MAX_LENGTH: 'maxlength',
	MAX: 'max',
	MIN: 'min',

	BE_MISSING_VALUE: 'value_error.missing',
	BE_VALUE_ERROR: 'value_error',
	BE_TYPE_ERROR: 'type_error',
	BE_NOT_EMAIL: 'value_error.email',
	FROM_TIME_BIGGER: 'from_time_bigger',
};

export const PERMISSIONS_NAME = {
	USER_MANAGEMENT: 'RIGHTS_USER_MANAGEMENT',
	RESTAURANT_MANAGEMENT: 'RIGHTS_RESTAURANT_MANAGEMENT',
	REPORTING: 'RIGHTS_REPORTING',
	PRICE_MANAGEMENT: 'RIGHTS_PRICE_MANAGEMENT',
	MONITORING: 'RIGHTS_MONITORING',
	SW_MANAGEMENT: 'RIGHTS_SW_MANAGEMENT',
	DATA_UPDATE_MANAGEMENT: 'RIGHTS_DATA_UPDATE_MANAGEMENT',
	WEB_ORDER_MANAGEMENT: 'RIGHTS_WEB_ORDER_MANAGEMENT',
	MEDIA_MANAGEMENT: 'RIGHTS_MEDIA_MANAGEMENT',
	ACCOUNTING: 'RIGHTS_ACCOUNTING',
};

export const DEFAULT_ROUTES = {
	LOGIN: '/login',
	FULLSCALE_UI: `${ROUTE_PATHS.fullscaleUi}/${ROUTE_PATHS.dashboard}`,
};

export const BODY = 'body';

export const PRICE_ROW_IDENTIFIER_SORT = {
	[ProductPriceType.Solo]: 0,
	[ProductPriceType.InSuggestion]: 1,
	[ProductPriceType.SupplementMenu]: 2,
	[MenuSize.M]: 0,
	[MenuSize.L]: 1,
};

export const CONFIG = {
	TIME_PICKER_OPTIONS: {
		okLabel: 'Submit',
		backdrop: true,
		clockType: '24h',
	},
};

export const REGEX = {
	// used to get correct time value (in format HH:MM) from other format like HH:MM:SS
	OPENING_TIME_REG: /^(?:(?:([01]?\d|2[0-3]):)([0-5]?\d))/g,
	// used in form control pattern validation to validate time format HH:MM
	OPENING_TIME_VALIDATION_REG: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
	// matches any two digits
	TWO_DIGITS_REG: /^\d{2}$/,
};

export const VAT_GROUPS = {
	STANDARD: 1,
	COURSE: 2,
};

export const MONITORING_CHART_COLOR_PALETTE = {
	activeWithoutWarning: '#04C071', // $SUCCESS
	warning: '#FF9D46', // $ATTENTION
	inactive: '#A2A9AF', // $mid-grey
	error: '#FF0000', // $CRITICAL
};

export const MONITORING_LAST_CONNECTION_DATE_FORMAT = 'dd/MM/yyy hh:mm';

export const MONITORING_ACTION_LINKS = {
	DATA_UPDATE: {
		ICON: 'cloud_upload',
		PORT: '8888',
		PATH: '/data/update',
	},
	APP_UPDATE: {
		ICON: 'settings',
		PORT: '8888',
		PATH: '/appupdate',
	},
	MANAGER: {
		ICON: 'manage_accounts',
		PORT: '8080',
	},
	WEBREPORTING: {
		ICON: 'table_chart',
		PORT: '5000',
	},
	GUACAMOLE: {
		ICON: 'point_of_sale',
		PATH: 'guacamole',
	},
	LOGS: {
		ICON: 'list',
		PATH: '/Shar3dRemoting/Command',
		QUERIES: {
			ACTION: 'action',
		},
	},
	WEB_ORDERS: {
		ICON: 'list_alt',
		PORT: '10123',
		PATH: 'order/list',
		QUERIES: {
			RESTO_UUID: 'restouuid',
			BUSINESS_DAY: 'businessday',
		},
	},
	PLUTUS_FISCAL_LINK: {
		ICON: 'attach_money',
		PORT: '1337',
		PATH: 'web/dashboard',
	},
};

export const INPUT_EVENT_TYPES = {
	INSERT_TEXT: 'insertText',
};

export const PASSWORD_TYPES = {
	NO_CHANGE: null,
	GENERATE: 0,
	SET_MANUALLY: 1,
	SEND_VIA_EMAIL: 2,
};

export const AUTH_TOKEN_URL_CONST = 'auth-token';
export const AUTH_TOKEN_BEARER = 'bearer';
export const DEFAULT_AUTH_TOKEN_EXPIRE_TIME = 604800;

export const IN_RESTAURANT = 'IN_RESTAURANT';
export const TABLE_QR_CODE_RESOLUTION_QUICK = {
	WIDTH: 250,
	HEIGHT: 460,
	QR_CODE_WIDTH: 250,
	QR_CODE_HEIGHT: 250,
	QR_CODE_X: 0,
	QR_CODE_Y: 210,
	COLOR: {
		DARK: '#cc0000',
		LIGHT: '#ffffff',
	},
	LOGO: {
		WIDTH: 120,
		HEIGHT: 180,
		X: 24,
		Y: 24,
	},
	TEXT_POSITION_MARGINS: {
		TABLE_X: -16,
		TABLE_Y: 90,
		NUMBER_X: -40,
		NUMBER_Y: 140,
	},
};
