import { Component, Inject } from '@angular/core';
import * as api from '@dki/api-client';
import { getLocale } from '@libs/dash/core';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { TranslateService } from '@libs/shared/modules/i18n';
import { combineLatest, filter, map, tap } from 'rxjs';
import { SOS_REPORT_FACADE } from '../facade/speed-of-service-facade.injection.token';
import { SOSReportServiceProvider } from '../facade/speed-of-service-facade.provider.interface';
import { SERVICE_OBJECTIVES, THRESHOLD_INDIVIDUAL, ValueType } from '@libs/dash/core/entity';
@Component({
	selector: 'dash-speed-of-service',
	templateUrl: './speed-of-service.component.html',
	styleUrls: ['./speed-of-service.component.scss'],
})
export class SpeedOfServiceComponent {
	constructor(
		@Inject(SOS_REPORT_FACADE) private readonly sosProvider: SOSReportServiceProvider,
		private _translateService: TranslateService
	) {}

	serviceObjectives = [
		{
			type: ValueType.Preparation,
			...SERVICE_OBJECTIVES[ValueType.Preparation],
			objective: THRESHOLD_INDIVIDUAL,
		},
	];

	myRestaurants$ = this.sosProvider.myRestaurants$;

	selectedRestaurants$ = this.sosProvider.selectedRestaurants$;

	isMultiSelectRestaurants$ = this.sosProvider.isMultiSelectRestaurant$;

	sosData$ = this.sosProvider.sosData$;

	sosDataByRestaurants$ = this.sosProvider.sosDataByRestaurant$;

	initDate = this.sosProvider.range;

	total = { depart_time: 0, preparation_time: 0, total: 0, count: 0 };

	viewData$ = combineLatest([
		this.myRestaurants$,
		this.sosData$,
		this.sosDataByRestaurants$,
		this._translateService.selectTranslation('sosKioskTile'),
	]).pipe(
		filter(([myRestaurants]) => myRestaurants.length > 0),
		tap(([, sosData]) => {
			if (sosData) {
				this._calculateTotals(sosData);
			}
		}),
		map(([myRestaurants, sosData, sosDataByRestaurants, i18n]) => ({
			myRestaurants,
			sosData,
			sosDataByRestaurants,
			i18n,
		}))
	);

	onRestaurantSelection(restaurant: api.Restaurant[]): void {
		this.sosProvider.setRestaurant(restaurant);
	}

	onDaysChanged(selectedDays: string[]): void {
		this.sosProvider.setDays(selectedDays);
	}

	onDateSelection(date: { from: DateTime; to: DateTime }): void {
		this.sosProvider.setDateRange(date.from, date.to);
	}

	onChannelSelection(channel: string | null): void {
		this.sosProvider.setChannel(channel);
	}

	private _calculateTotals(sosData: any[]): void {
		const validPeriods = sosData.filter(this._isValidPeriod);
		const totalPreparationTime = validPeriods.reduce((sum, period) => sum + period.preparation_time, 0);
		const totalDepartTime = validPeriods.reduce((sum, period) => sum + period.serve_time, 0);
		const totalValidPeriods = validPeriods.length;

		this.total = {
			preparation_time: totalValidPeriods > 0 ? totalPreparationTime / totalValidPeriods : 0,
			depart_time: totalValidPeriods > 0 ? totalDepartTime / totalValidPeriods : 0,
			total: totalValidPeriods > 0 ? (totalPreparationTime + totalDepartTime) / totalValidPeriods : 0,
			count: totalValidPeriods,
		};
	}

	private _isValidPeriod(period: any): boolean {
		return (
			period.preparation_time != null &&
			!isNaN(period.preparation_time) &&
			period.depart_time != null &&
			!isNaN(period.depart_time) &&
			period.count > 0
		);
	}

	calculatePercent(type: ValueType): number {
		const objective = SERVICE_OBJECTIVES[type];
		if (!objective) {
			return 0;
		}
		const value = this.total[objective.timeStep];
		if (!value) {
			return 0;
		}
		return (value - objective.threshold) / objective.threshold;
	}

	export() {
		const source = document.getElementById('data-table');
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		const header = 'SOS';
		doc.html(source, {
			html2canvas: {
				scale: 0.28,
				letterRendering: false,
				ignoreElements: (e) =>
					e.classList.contains('arrow') || e.classList.contains('ignore') || e.classList.contains('mat-mdc-button-touch-target'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1920,
			width: 1800,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.setFontSize(16);
				doc.setFont(undefined, 'bold');
				doc.text(header, 20, 15);
				const from = this.sosProvider.range.from.setLocale(getLocale()).toFormat('yyyy LLL dd'),
					to = this.sosProvider.range.to.setLocale(getLocale()).toFormat('yyyy LLL dd');
				const date = from === to ? from : `${from} - ${to}`;
				doc.setFontSize(12);
				doc.setFont(undefined, 'light');
				// doc.text(`${footer}`, 20, 200, { align: 'left' });
				doc.text(`${date}`, 20, 20);
				// doc.text(`${source_type}`, 285, 20, { align: 'right' });
				doc.save('Sos Report ' + this.sosProvider.range.from.toISODate() + '-' + this.sosProvider.range.to.toISODate());
			},
		});
	}
}
