import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ColumnType, CommonLayoutTableComponent, CommonTableConfig } from '@libs/shared/modules/common-components';
import {
	OperatingReportInternalData,
	OperatingReportsTabComponent,
	OperatingReportTableData,
} from '../../partial-views/operating-reports-tab/operating-reports-tab.component';
import { OperatingReportsTableConfigFilterPipe } from "../../partial-views/operating-reports-tab/operating-reports-table-config-filter.pipe";
import { OperatingReportsTableDataFilterPipe } from "../../partial-views/operating-reports-tab/operating-reports-table-data-filter.pipe";

export const OPERATING_REPORTS_COLUMNS = {
	Date: 'key',
	POS: 'POS',
	Kiosk: 'KIOSK',
	Total: 'Total',
};

@Component({
	selector: 'operating-reports-tab-table',
	standalone: true,
	imports: [CommonModule, CommonLayoutTableComponent, OperatingReportsTableConfigFilterPipe, OperatingReportsTableDataFilterPipe],
	templateUrl: './operating-reports-tab-table.component.html',
	styleUrl: './operating-reports-tab-table.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatingReportsTabTableComponent {
	dataMapped = input.required<OperatingReportTableData[]>();
	data = input.required<OperatingReportInternalData>();
	extendedComponentRef = input.required<OperatingReportsTabComponent>();
	selectedChannels = input.required<string[]>();
	tableConfigSig = computed<CommonTableConfig<any>>(() => {
		if (this.data()) {
			return this.generateConfig();
		}

		return null;
	});

	private generateConfig(): CommonTableConfig<any> {
		return {
			titleKey: 'operatingReportTile.title-new',
			columns: [
				{
					key: OPERATING_REPORTS_COLUMNS.Date,
					columnType: ColumnType.Date,
					totalColumnType: ColumnType.Translation,
					headerLabelKey: 'operatingReportTile.date',
					alignment: 'right',
					totalGetter: () => 'operatingReportTile.total',
				},
				{
					key: OPERATING_REPORTS_COLUMNS.POS,
					columnType: ColumnType.Price,
					headerLabelKey: 'operatingReportTile.channels.POS',
					alignment: 'right',
					valueGetter: (cell: unknown, row: any) => this.extendedComponentRef().totalPos({ value: row }, true),
					totalGetter: () => this.extendedComponentRef().totalPos(this.data().operatingReport, false),
				},
				{
					key: OPERATING_REPORTS_COLUMNS.Kiosk,
					columnType: ColumnType.Price,
					headerLabelKey: 'operatingReportTile.channels.KIOSK',
					alignment: 'right',
					valueGetter: (cell: unknown, row: any) => this.extendedComponentRef().totalKiosk({ value: row }, true),
					totalGetter: () => this.extendedComponentRef().totalKiosk(this.data().operatingReport, false),
				},
				...Object.keys(this.data()?.operatingReport?.total?.by_channel)
					.filter(
						(key) =>
							this.data()?.operatingReport?.total?.by_channel[key].value &&
							this.extendedComponentRef().isVisible(key) &&
							!this.extendedComponentRef().hiddenChannel(key)
					)
					.sort((a, b) => this.extendedComponentRef().sortChannels({ key: a }, { key: b }))
					.map((key) => {
						return {
							key,
							columnType: ColumnType.Price,
							alignment: 'right',
							headerLabelKey: `operatingReportTile.channels.${key}`,
							valueGetter: (cell: unknown, row: OperatingReportTableData) => row.by_channel[key].value,
							totalGetter: (data: OperatingReportTableData[]) => this.data().operatingReport.total.by_channel[key].value,
						};
					}),
				{
					key: OPERATING_REPORTS_COLUMNS.Total,
					columnType: ColumnType.Price,
					alignment: 'right',
					headerLabelKey: 'operatingReportTile.total',
					classGetter: () => '!bg-surface-grey',
					valueGetter: (cell: unknown, row: any) => this.extendedComponentRef().totalPerRow({ value: row }),
					totalGetter: () => this.extendedComponentRef().totalPerData(),
				},
			],
		};
	}
}
