import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import {
	Period,
	IPeriodData,
	PERIODS_ORDER,
	THRESHOLD_INDIVIDUAL,
	THRESHOLD_TOTAL,
	TimeStep,
	IAggregateData,
	ValueType,
} from '@libs/dash/core/entity';
import { TranslateService } from '@libs/shared/modules/i18n';
import { Observable } from 'rxjs';

@Component({
	selector: 'data-table-cumul',
	templateUrl: './data-table-cumul.component.html',
	styleUrls: ['./data-table-cumul.component.scss'],
})
export class DataTableCumulComponent implements AfterViewInit {
	@ViewChild('secondLabel') secondLabel: ElementRef<HTMLElement>;
	@ViewChild('cell') cell: ElementRef<HTMLElement>;
	@Input() data: IPeriodData[] = [];

	periodsOrder = PERIODS_ORDER;
	ValueType = ValueType;
	TimeStep = TimeStep;

	translations$: Observable<any>;

	constructor(private readonly translateService: TranslateService) {}

	ngOnInit() {
		this.translations$ = this.translateService.selectTranslation('sosKioskTile');
	}

	ngAfterViewInit(): void {
		this.positionSecondLabel();
	}

	@HostListener('window:resize')
	onResize(): void {
		this.positionSecondLabel();
	}

	protected positionSecondLabel(): void {
		const referenceRect = this.cell.nativeElement.getBoundingClientRect();
		const targetStyle = this.secondLabel.nativeElement.style;
		targetStyle.position = 'absolute';
		targetStyle.marginLeft = '-6ch';
		targetStyle.left = `${referenceRect.left}px`;
		targetStyle.height = `${referenceRect.height}px`;
	}

	formatTimeRange(value: { start: number; end: number }): string {
		const formatTime = (time: number) => time.toString().padStart(2, '0');
		const template = this.translateService.instant('sosKioskTile.timeRange');
		return template.replace('{start}', formatTime(value.start)).replace('{end}', formatTime(value.end));
	}

	isPeriodData(data: IPeriodData | IAggregateData): data is IPeriodData {
		return 'start' in data && 'end' in data;
	}

	getTotalForField(field: keyof IPeriodData | TimeStep): number {
		return this.data.reduce((total, period) => {
			const value = period[field as keyof IPeriodData];
			return typeof value === 'number' ? total + value : total;
		}, 0);
	}

	getSortedPeriods(): [Period, IPeriodData][] {
		return this.periodsOrder
			.filter((period) => this.data[period] && this.isPeriodData(this.data[period]))
			.map((period) => [period, this.data[period] as IPeriodData]);
	}

	calculateAverageForField(field: keyof IPeriodData | TimeStep): number {
		let total = 0;
		let count = 0;

		this.data.forEach((period) => {
			if (field === TimeStep.PreparationTime || field === TimeStep.ServeTime || field === TimeStep.DepartTime) {
				total += (period[field] || 0) * period.count;
			} else if (typeof period[field as keyof IPeriodData] === 'number') {
				total += period[field as keyof IPeriodData] as number;
			}
			count += period.count;
		});

		return count > 0 ? total / count : 0;
	}

	getClassForValue(value: number, type: ValueType): string {
		if (!value) {
			return 'white';
		}
		return this.isThresholdExceeded(value, type) ? 'red' : 'green';
	}

	isThresholdExceeded(value: number, type: ValueType): boolean {
		return type === ValueType.Total ? value > THRESHOLD_TOTAL : value > THRESHOLD_INDIVIDUAL;
	}
}
