/**
 * Matches the props in IFeatureConfigurationState
 */
export enum CONFIGURATION_DOWNLOAD_TYPES {
	BIG_DATA = 'bigData',
	AVAILABILITY = 'availability',
	RESTO_SETTINGS = 'restoSettings',
	RESTO_CONFIG = 'restoConfig',
}

export enum RING_MESSAGE_TYPES {
	UNKNOWN = 'Unknown',
}

export enum MESSAGE_TOPICS {
	FROM_BK_GROUP = 'frombkgroup',
	TO_BK_GROUP = 'tobkgroup',
	SCAN_MESSAGES = 'scan_messages',
}

export enum LOCAL_STORAGE_KEYS {
	ACCESS_TOKEN = 'access_token',
	CLERK_JWT = 'clerk-jwt',
	TOGGLE_SHOW_ONLY_ACTIVE_ITEMS = 'toggle_show_only_active_items',
	LANGUAGE = "lang"
}

export enum DEVICES_USER_ROLES {
	MANAGER = 1,
	FRANCHISE = 3,
	MAINTAINER = 7,
}

export enum ERROR_STORE_SEVERITY_TYPES {
	HIGHEST = 1,
	HIGH = 2,
	MODERATE = 3,
	LOW = 4,
	LOWEST = 5,
}

export enum ERROR_STORE_ORIGINATORS {
	CLERK = 'clerk',
	UNKNOWN = 'unknown',
}

export enum MENU_MODEL_ACTION_CONDITION {
	NONE,
	PERMITTED,
	PERMITTED_ENABLED,
	ENABLED,
}

export enum IS_LOGGED_IN_USER_ROLES {
	MANAGER = 'manager',
	OPERATEUR = 'operateur',
}

export enum MENU_SIZE {
	MEDIUM = 'MENU_SIZE_MEDIUM',
	LARGE = 'MENU_SIZE_LARGE',
}

export enum UPDATE_TYPE {
	ADD,
	REMOVE,
}

export enum ApplicationNavigationScreen {
	KIOSK,
	POS,
}
