export const GAP_COLORS = {
	USER_MANAGEMENT: 'rgba(30, 149, 235, 1)',
	RESTAURANT_MANAGEMENT: 'rgba(110, 87, 193, 1)',
	PRICE_MANAGEMENT: 'rgba(247, 181, 0, 1)',
	MONITORING: 'rgba(126, 8, 120, 1)',
	ABOUT: 'rgba(196, 59, 92, 1)',
	ALLERGEN: 'rgba(63, 154, 135, 1)',
	RESTAURANT_OWNERS_MANAGEMENT: 'rgba(63, 154, 135, 1)',
	RESTAURANT_PAYMENTS_MANAGEMENT: 'rgba(255, 194, 102, 1)',
	RESTAURANT_LIST_MANAGEMENT: 'rgba(217, 81, 56, 1)',
	RESTAURANT_TAG_MANAGEMENT: 'rgb(202,152,135)',
	MEDIA_MANAGEMENT: 'rgba(0, 168, 107, 1)',
	OPTIONS_MANAGEMENT: 'rgb(243,160,110)',
};
