<div class="rounded-xl flex items-center p-2 bg-surface-veryLightGrey">
	<div class="flex justify-between items-center gap-1 rounded-lg p-2 flex-shrink-0 w-20" [ngClass]="statusClass">
		<span class="material-icons text-xl">{{ icon }}</span>
		<div class="text-xl font-semibold font-sarabun">
			{{ absPercent | percent }}
		</div>
	</div>
	<div class="flex flex-col ml-4 flex-grow overflow-hidden">
		<div class="text-accent-900 text-lg font-sarabun text-ellipsis overflow-hidden whitespace-nowrap" [translate]="labelKey"></div>
		<div class="text-surface-darkGrey text-xs font-sarabun leading-tight tracking-tight text-ellipsis overflow-hidden whitespace-nowrap">
			<span [translate]="'sosKioskTile.indicators.objective'"></span> {{ objectiveMs | date: 'mm:ss' }}
			<span [translate]="'sosKioskTile.indicators.minutes'"></span>
		</div>
	</div>
</div>
